import React from 'react';
import { useNavigate } from 'react-router-dom';
import './IntroPage.css'; // Optional: create a CSS file for styling
import { Button } from '@mui/material';

function IntroPage() {
  const navigate = useNavigate();

  const startSurvey = () => {
    navigate('/waterstone/spiritual-gifts/start');
  };

  return (
    <div className="intro-page">
            <h2>WaterStone Church</h2>
            <h5>Spiritual Gifts Assessment</h5>
      <p>At the request of Waterstone Church the spiritual gifts testing has been taken down.</p>

      <Button disabled variant="contained" onClick={startSurvey}>Start Assessment</Button>
      < br />

    </div>
  );
}

export default IntroPage;
